import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import GradingIcon from "@mui/icons-material/Grading";
import MenuIcon from "@mui/icons-material/Menu";
import PrintIcon from "@mui/icons-material/Print";
import Divider from "@mui/material/Divider";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { alpha, styled, useTheme } from "@mui/material/styles";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Editbooks from "../components/Editbooks";
import { deleteBook, updateBookStatus } from "../redux/action/action";
import BookComponent from "./Book/BookComponent";
import ReactBookLoader from "./ReactBookLoader";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
export default function BookCards({ onBookUpdate }) {
  const navigate = useNavigate();
  const listBooks = useSelector((s) => s.reducer.data.books);
  const loading = useSelector((s) => s.reducer.loading);
  const dispatch = useDispatch();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState();
  const open = Boolean(anchorEl);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedBookIds, setSelectedBookIds] = useState({});
  const [selectedBookId, setSelectedBookId] = useState(null);
  const [headingState, setHeadingState] = useState(null);
  const openModal = () => {
    setModalOpen(true);
  };
  const handleClick = (event, bookId) => {
    setAnchorEl(event.currentTarget);
    setSelectedBookIds((prevIds) => ({ ...prevIds, [bookId]: true }));
  };
  const handleClose = () => {
    setAnchorEl(null);
    setSelectedBookIds({});
  };
  const openEditModal = (bookId) => {
    setSelectedBookId(bookId);
    setModalOpen(true);
    setHeadingState("Edit Book Details");
  };

  const handleStatusChange = (bookId, newStatus) => {
    dispatch(updateBookStatus(bookId, newStatus));
  };
  return (
    <>
      {loading ? (
        <ReactBookLoader />
      ) : listBooks && listBooks.length > 0 ? (
        <div className="mt-5 " style={{ padding: "30px" }}>
          <div className="mt-5 d-flex flex-wrap">
            {listBooks.map((book, i) => (
              <div
                className=""
                to={`/editor/${book._id}`}
                id="1"
                key={i}
                style={{
                  textDecoration: "none",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "75%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignSelf: "flex-end",
                    }}
                  >
                    <MenuIcon
                      onClick={(event) => handleClick(event, book._id)}
                      style={{ cursor: "pointer" }}
                    />
                    <StyledMenu
                      id="demo-customized-menu"
                      MenuListProps={{
                        "aria-labelledby": "demo-customized-button",
                      }}
                      anchorEl={anchorEl}
                      open={open && selectedBookIds[book._id]}
                      onClose={handleClose}
                    >
                      <Link onClick={() => openEditModal(book._id)}>
                        <MenuItem onClick={handleClose} disableRipple>
                          <EditIcon />
                          Edit
                        </MenuItem>
                      </Link>

                      <MenuItem
                        onClick={(event) => {
                          event.stopPropagation();
                          event.preventDefault();
                          if (
                            window.confirm("Are you sure you want to delete?")
                          ) {
                            dispatch(deleteBook(book._id));
                          } else {
                            return;
                          }
                          handleClose();
                        }}
                        disableRipple
                      >
                        <DeleteOutlineIcon />
                        Delete
                      </MenuItem>

                      <MenuItem
                        onClick={() => {
                          handleClose();
                          navigate(`/orders/${book._id}`);
                        }}
                        disableRipple
                      >
                        <GradingIcon />
                        Order the Book
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleClose();
                          navigate(`/bookcovereditor/${book._id}`);
                        }}
                        disableRipple
                      >
                        <GradingIcon />
                        Create the Book Cover
                      </MenuItem>

                      <MenuItem
                        onClick={() => {
                          handleClose();
                          if (book.newOrder) {
                            handleStatusChange(book._id, "Submitted for Print");
                          }
                        }}
                        disableRipple
                        disabled={!book.newOrder}
                      >
                        <PrintIcon />
                        Submit for Print
                      </MenuItem>
                      <Divider sx={{ my: 0.5 }} />
                    </StyledMenu>
                    <Editbooks
                      open={isModalOpen}
                      setModalOpen={setModalOpen}
                      heading={headingState}
                      bookId={selectedBookId}
                      onBookUpdate={onBookUpdate}
                    />
                  </div>
                  <Link
                    to={`/editor/${book._id}`}
                    id="1"
                    style={{ textDecoration: "none" }}
                  >
                    <BookComponent
                      title={book.title}
                      subtitle={book.subtitle}
                      author={book.author}
                      imageUrl={book.imageUrl}
                      bookId={book._id}
                      bookFrontCover={book.bookFrontCover}
                    />
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="mt-5" style={{ textAlign: "center" }}>
          <h4>No books available</h4>
        </div>
      )}
    </>
  );
}
